import axios from '@/utils/request'
// import { apiRoot } from './apiroot.autogen'

// axios.baseURL = apiRoot

export default async (url = '', data = {}, type = 'GET', responseType = 'json') => {
  type = type.toUpperCase()

  const instance = await axios.request({
    method: type,
    url: url,
    data: type === 'GET' ? null : data,
    params: type === 'GET' ? data : null,
    responseType
  })

  return instance
}
