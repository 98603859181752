<template>
  <div id="app" @mousemove="mousemove">
    <screen-saver v-if="showScreenSaver" msg="Schattdecor Show Room"></screen-saver>
    <page-detail
      ref="page"
      @getKeyCode="getKeyCode"
      @setIsFullScreen="setIsFullScreen"
      @returnScreenSaver="returnScreenSaver"
      :showScreenSaver="showScreenSaver"
    ></page-detail>
    <template v-if="!isHideCursor">
      <!-- <image class="icon_fullScreen" :src="fullScreenIcon" mode=""></image> -->
      <img v-show="!isFullScreen" @click="isFullScreen = true" class="icon_fullScreen" src="./assets/fullScreen.png" mode="" />
      <img v-show="isFullScreen" @click="isFullScreen = false" class="icon_fullScreen" src="./assets/escFullScreen.png" mode="" />
    </template>
  </div>
</template>

<script>
import ScreenSaver from './components/ScreenSaver.vue'
import PageDetail from './components/PageDetail.vue'
export default {
  components: { ScreenSaver, PageDetail },
  name: 'App',
  data() {
    return {
      sleepTimer: null,
      showScreenSaver: true,
      isFullScreen: false,
      watchCursorTime: null,
      isHideCursor: false
    }
  },

  created() {
    this.sleepTimer = setTimeout(() => {
      this.showScreenSaver = true
    }, 60 * 1000 * 15)
  },

  beforeMount() {
    // 模拟鼠标移动
    this.mousemove()
  },

  watch: {
    isHideCursor(v, oldV) {
      if (v === oldV) return
      document.body.style.cursor = v ? 'none' : ''
      // 检查是否全屏，防止Esc键退出全屏，isFullScreen没有被设置为false
      this.correctIsFullScreen()
    },
    isFullScreen(v, oldV) {
      if (oldV == v) return

      // 检查是否有必要全屏、退出全屏
      const vReal = this.checkFull()
      if (v === vReal) return

      if (v) this.enterFullScreen()
      else this.exitFullscreen()
    }
  },

  methods: {
    mousemove() {
      // 监听鼠标移动，如果鼠标移动就重新计时
      clearTimeout(this.watchCursorTime)
      this.isHideCursor = false
      this.watchCursorTime = null
      this.watchCursorTime = setTimeout(() => {
        this.isHideCursor = true
      }, 2000)
    },
    // 开启全屏
    enterFullScreen() {
      let docElm = document.documentElement
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      }
      // this.checkFull()
    },
    // 退出全屏
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
      // this.checkFull()
    },
    // 矫正全屏
    correctIsFullScreen() {
      this.isFullScreen = this.checkFull()
    },

    // 判断全屏
    checkFull() {
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      let isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) {
        isFull = false
      }
      return isFull
    },

    setIsFullScreen(e) {
      this.isFullScreen = e
    },
    returnScreenSaver() {
      this.showScreenSaver = true
    },
    getKeyCode(keyCode) {
      // console.log('keyCode', keyCode)
      // 没有任何操作15s后开启屏保

      // 回车键：进入详情
      if (keyCode === 13) {
        this.showScreenSaver = false
      }

      // backspace键：判断是否全屏，并进入全屏
      if (keyCode === 8) {
        this.isFullScreen = true
      }

      // esc键：判断是否全屏，并推出全屏
      if (keyCode === 27) {
        this.isFullScreen = false
      }

      clearTimeout(this.sleepTimer)
      this.sleepTimer = setTimeout(() => {
        this.showScreenSaver = true
      }, 60 * 1000 * 15)
    }
  }
}
</script>

<style>
.icon_fullScreen {
  position: absolute;
  top: 2vw;
  right: 2vw;
  z-index: 99;
  width: 2.2vw;
  height: 2.2vw;
}
.indicator_view {
  position: fixed;
  bottom: 15px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  margin: 0 0;
  padding: 0 0;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #fff; */
  height: 100vh;
  width: 100vw;
}
</style>
