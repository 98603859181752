var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_container",attrs:{"id":"pageA"}},[_c('div',{staticClass:"grid-container",style:({ color: _vm.curColor })},[_c('img',{staticClass:"bgImg",attrs:{"src":_vm.getImgUrl(_vm.curBackgroundUrl),"mode":""}}),_c('div',{staticClass:"grid-column1 grid-column"},[_c('img',{staticClass:"logo",style:({ filter: `invert(${_vm.curinvert})` }),attrs:{"src":require("../assets/logo/logo_b.png"),"mode":""}})]),_c('div',{staticClass:"grid-column2 grid-column"},[_c('div',[_c('img',{staticClass:"bigImage",attrs:{"src":_vm.curBigImage,"mode":""}})])]),_c('div',{staticClass:"grid-column3 grid-column"},[_c('div',{staticClass:"grid-column3-box"},[_c('div',{staticClass:"selector_row",class:{ dithering: _vm.openDithering }},[(_vm.currentIndex - 1 < 0)?_c('div',{staticClass:"placeholder1"}):_vm._e(),(_vm.currentIndex - 2 < 0)?_c('div',{staticClass:"placeholder2"}):_vm._e(),_vm._l((_vm.decorItems),function(item,index){return _c('div',{key:index,class:{
              sm_image_box0: index === _vm.currentIndex - 2,
              sm_image_box1: index === _vm.currentIndex - 1,
              sm_image_box2: index === _vm.currentIndex,
              sm_image_box3: index === _vm.currentIndex + 1,
              sm_image_box4: index === _vm.currentIndex + 2,
              sm_image_box5: index === _vm.currentIndex + 3
            },style:({
              display:
                index !== _vm.currentIndex - 2 &&
                index !== _vm.currentIndex - 1 &&
                index !== _vm.currentIndex &&
                index !== _vm.currentIndex + 1 &&
                index !== _vm.currentIndex + 2 &&
                index !== _vm.currentIndex + 3
                  ? 'none'
                  : ''
            })},[(_vm.decorItems[index].smallImage)?_c('img',{attrs:{"src":_vm.decorItems[index].smallImage,"alt":""}}):_vm._e()])})],2)])]),_c('div',{staticClass:"grid-column4 grid-column"},[_c('div',{staticClass:"grid-column4-container"},[_c('div',{staticClass:"en_title"},[_vm._v(_vm._s(_vm.stringToUpper(_vm.curName)))]),_c('div',{staticClass:"cn_title"},[_vm._v(_vm._s(_vm.curCnName))]),_c('div',{staticClass:"long_code"},[_vm._v(" "+_vm._s(_vm.curLongCode)+" "),(_vm.decorFiles.length > 0)?_c('img',{staticClass:"image-icon",style:({ filter: `invert(${_vm.curinvert})` }),attrs:{"src":require("../assets/image-icon.png"),"mode":""}}):_vm._e()]),_c('div',{staticClass:"size"},[_c('span',{staticClass:"en_style"},[_vm._v("Repeat")]),_vm._v(" / "),_c('span',{staticStyle:{"font-family":"DengXian, Arial"}},[_vm._v("尺寸：")]),_c('span',{staticClass:"curSize_font"},[_vm._v(_vm._s(_vm.curSize))])]),_c('div',{staticClass:"describe"},[_vm._v(" "+_vm._s(_vm.curComment)+" ")]),_c('div',{staticClass:"other_container"},[(_vm.decorFiles.length === 0)?_c('div',{staticClass:"gif_container"}):_c('div',{staticClass:"gif_container2"}),_vm._m(0)]),_vm._m(1),_c('canvas',{staticClass:"mycanvas",style:({ filter: `invert(${_vm.curinvert})` }),attrs:{"id":"canvas"}})])]),(_vm.showCurDetailImage)?_c('div',{staticClass:"detailBigImage"},[_c('img',{attrs:{"src":_vm.decorFiles[_vm.currentRenderingIndex].bigImage,"mode":""}}),_c('div',{staticClass:"curIndexrenderings"},[_vm._v(_vm._s(_vm.currentRenderingIndex + 1)+" / "+_vm._s(_vm.decorFiles.length))])]):_vm._e(),_c('curShowToast',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowToast),expression:"isShowToast"}],attrs:{"toastValue":_vm.toastValue}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gif_text"},[_c('div',{staticStyle:{"font-family":"DengXian, Arial"}},[_vm._v("请转动按钮，查看此花色其他颜色")]),_c('div',{staticStyle:{"font-family":"DengXian, Arial"}},[_vm._v("拍动查看应用案例")]),_c('div',{staticStyle:{"font-family":"Century Gothic, arial"}},[_vm._v("Tune the button to explore various colors.")]),_c('div',{staticStyle:{"font-family":"Century Gothic, arial"}},[_vm._v("Tap to view rendered images.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qrcode"},[_c('div',{staticStyle:{"margin-bottom":"0.8vw","font-weight":"300","line-height":"1em","font-size":"0.8vw"}},[_c('div',{staticStyle:{"font-family":"DengXian, Arial"}},[_vm._v("手机微信扫描二维码")]),_c('div',{staticStyle:{"font-family":"DengXian, Arial"}},[_vm._v("查看花色详情")]),_c('div',{staticStyle:{"font-family":"Century Gothic, arial"}},[_vm._v("Scan it...")])]),_c('div',{staticClass:"qrcode_imgbox"})])
}]

export { render, staticRenderFns }