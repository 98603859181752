import fetch from '@/api/fetch'

// 花色查询
export const getDecor = async (decorID,decorItemID) => fetch(`/api/v1/svc/showroom/decor/${decorID}/${decorItemID}`)

// 获取二维码
export const getQrCode  = async (decorID,decorItemID) => fetch(`/api/v1/svc/showroom/qrcode/${decorID}/${decorItemID}`, null , 'GET' , 'blob')



export default {
  getDecor,
  getQrCode
}
