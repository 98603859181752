<template>
  <div>
    <!--Hey! This is the original version of Simple CSS Waves-->
    <!-- 渐变背景 -->
    <gradientBg :msg="msg"></gradientBg>
  </div>
</template>

<script>
import gradientBg from './GradientBg'
export default {
  name: 'ScreenSaver',
  components: {
    gradientBg
  },
  props: {
    msg: String
  },
  data() {
    return {
      currentScreenID: 0
    }
  },
  methods: {
    changeScreen(index) {
      this.currentScreenID = index
    }
  },
  mounted() {
    // 禁用滚动条
    const body = window.document.getElementsByTagName('body')[0]
    body.style.overflow = 'hidden'
  }
}
</script>

<style scoped></style>
