<template>
  <!-- 颗粒 -->
  <div>
    <!-- <div class="context">
      {{ msg }}
    </div> -->
    <div class="gradient-bg"></div>
    <img class="logo2" src="../assets/logo2.png" mode="widthFix" />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    msg: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
.logo2 {
  width: 50vw;
  position: fixed;
  z-index: 5;
  display: block;
  margin: auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  filter: invert(100%);
}
.gradient-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // background: linear-gradient(135deg, #f66, #f90, #3c9, #09f, #66f) left center/400% 400%;
  background: linear-gradient(135deg, #f66, #f90, #3c9, #988b9f, #3c3642) left center/600%;
  font-weight: bold;
  font-size: 70px;
  color: #fff;
  animation: move 12s infinite;
}
@keyframes move {
  0%,
  100% {
    background-position-x: left;
  }
  50% {
    background-position-x: right;
  }
}
</style>
