import axios from 'axios'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// import { errors } from './error'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// // request interceptor
// service.interceptors.request.use(
//   config => {
//     // // Do something before request is sent
//     // if (store.getters.token) {
//     //   config.headers['Authorization'] = getToken()
//     // }
//     // config.headers['Accept-Language'] = store.getters.language || 'zh'
//     // const content = config.url
//     // console.log(content)
//     // return config
//   },
//   error => {
//     // Do something with request error
//     console.error('reqest error:', error) // for debug
//     return Promise.reject(error)
//   }
// )

// response interceptor
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    let customErrorMsg
    const code = (error.response && error.response.status) || 5555
    if (code === 401) {
      customErrorMsg = '当前用户没有权限调用'
      // } else if (code === 403) {
      //   customErrorMsg = '无权限访问'
    } else if (code === 400) {
      customErrorMsg = (error.response.data && error.response.data.error) || 'API调用错误'
    }

    console.error('response error:' + error) // for debug

    if (customErrorMsg || error.response) {
      console.log('API提示:',customErrorMsg || error.response)
      // uni.showModal({
      //   title: 'API提示',
      //   content: customErrorMsg || error.response,
      //   showCancel: false
      // })
    }

    return Promise.reject(customErrorMsg || error.message || 'Error')
  }
)

export default service
