<template>
  <div class="wrap">{{ toastValue }}</div>
</template>
<script>
export default {
  name: 'Toast',
  data() {
    return {}
  },
  props: {
    toastValue: {
      type: String,
      default: '我是弹窗'
    }
  }
}
</script>
<style scoped>
.wrap {
  position: fixed;
  left: 50%;
  top: 50%;
  background: rgba(0, 0, 0, 0.35);
  padding: 16px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0;
  animation: showToast step-start 1.5s;
}
@keyframes showToast {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
